import React from 'react';
import NavTop from './NavTop';
import Navside from './Navside';

const AdminLayout = () => {
  return (
    <div>
      {/* <section> */}
      <NavTop />
      <Navside />
      {/* <Layout /> */}
      {/* <Footer /> */}
      {/* </section> */}
    </div>
  );
};

export default AdminLayout;
